import JSONAPI, {
  RelationshipOptions,
  ResourceObject,
  JsonApiObject,
  LinksObject,
  ErrorObject,
  Meta,
} from "json-api-serializer";

const jsonAPISerializer = new JSONAPI({
  convertCase: "snake_case",
  unconvertCase: "camelCase",
});

export interface Options extends JSONAPI.Options {
  relationships?: {
    [key in KnownTypeList]?: RelationshipOptions & { type: KnownTypeList };
  };
}

export interface JSONAPIDocument<T> {
  jsonapi?: JsonApiObject;
  links?: LinksObject;
  data?: ResourceObject<T> | Array<ResourceObject<T>>;
  errors?: ErrorObject[];
  meta?: Meta;
  included?: Array<ResourceObject<T>>;
}

export type Schema = KnownTypeList | Options;

function register(type: KnownTypeList, schema?: Schema, opts?: Options) {
  jsonAPISerializer.register(type, schema, opts);
}
export class Serializer<T extends (meta: unknown) => any> {
  private type: string;

  private metaAdapter: T;

  constructor(type: string, metaAdapter: T) {
    this.type = type;
    this.metaAdapter = metaAdapter;
  }

  public getMetaAdapter(meta: unknown) {
    return this.metaAdapter(meta);
  }

  public serialize<T>(data?: T, schema?: Schema) {
    return jsonAPISerializer.serialize(this.type, data, schema);
  }

  public deserialize<T>(data: JSONAPIDocument<T>, schemaType?: string): T {
    return jsonAPISerializer.deserialize(this.type, data, schemaType);
  }

  public getType() {
    return this.type;
  }
}

export type KnownTypeList = keyof typeof partialSerializers;
const partialSerializers = {
  template: new Serializer("template", () => ({})),
  community: new Serializer("community", () => ({})),
  item: new Serializer("item", (meta: any) => meta?.pagination),
  redemption: new Serializer("redemption", () => ({})),
  partner: new Serializer("partner", () => ({})),
  category: new Serializer("category", () => ({})),
  collection_header: new Serializer("collection_header", () => ({})),
  collection: new Serializer("collection", () => ({})),
  sub_collection: new Serializer("sub_collection", () => ({})),
  token: new Serializer("token", () => ({})),
  auth: new Serializer("auth", () => ({})),
  manager_organization: new Serializer("manager_organization", () => ({})),
  seller: new Serializer("seller", () => ({})),
  categories: new Serializer("categories", () => ({})),
  collections: new Serializer("collections", () => ({})),
  sub_collections: new Serializer("sub_collections", () => ({})),
  deal: new Serializer("deal", () => ({})),
  user: new Serializer("user", () => ({})),
  buyer_organization: new Serializer("buyer_organization", () => ({})),
  media_file: new Serializer("media_file", () => ({})),
  analytics_event: new Serializer("analytics_event", () => ({})),
  organization: new Serializer("organization", () => ({})),
  listing_edition: new Serializer("listing_edition", () => ({})),
  listing_feature: new Serializer("listing_feature", () => ({})),
  listing_edition_benefit: new Serializer(
    "listing_edition_benefit",
    () => ({}),
  ),
  listing_edition_item: new Serializer("listing_edition_item", () => ({})),
  listing_edition_plan: new Serializer("listing_edition_plan", () => ({})),
  listing_edition_plan_contract: new Serializer(
    "listing_edition_plan_contract",
    () => ({}),
  ),
  listing_edition_plan_cost: new Serializer(
    "listing_edition_plan_cost",
    () => ({}),
  ),
  password: new Serializer("password", () => ({})),
  pending_redemption: new Serializer("pending_redemption", () => ({})),
  helpful_link: new Serializer("helpful_links", () => ({})),
  showcased_organization: new Serializer("showcased_organization", () => ({})),
  font: new Serializer("font", () => ({})),
  reviews: new Serializer("reviews", () => ({})),
  dealQualification: new Serializer("dealQualification", () => ({})),
  quote_setting: new Serializer("quote_setting", () => ({})),
  managers: new Serializer("managers", () => ({})),
  embed_url: new Serializer("embed_url", () => ({})),
  branding_setting: new Serializer("branding_setting", () => ({})),
  field: new Serializer("field", () => ({})),
  form: new Serializer("form", () => ({})),
  field_rule: new Serializer("field_rule", () => ({})),
  field_file: new Serializer("field_file", () => ({})),
  quote_response: new Serializer("quote_response", () => ({})),
  buyer_user: new Serializer("buyer_user", () => ({})),
  form_response: new Serializer("form_response", () => ({})),
  quote_request: new Serializer(
    "quote_request",
    (meta: any) => meta?.pagination,
  ),
  field_option: new Serializer("field_option", () => ({})),
} as const;
register("community"); // TODO: improve this process
register("item");
register("redemption");
register("partner");
register("category");
register("collection_header");
register("collection");
register("sub_collection");
register("token");
register("auth");
register("category");
register("collection");
register("manager_organization");
register("seller");
register("categories");
register("collections");
register("sub_collections");
register("deal");
register("user");
register("buyer_organization");
register("media_file");
register("organization");
register("listing_edition");
register("listing_feature");
register("listing_edition_benefit");
register("listing_edition_item");
register("listing_edition_plan");
register("listing_edition_plan_cost");
register("listing_edition_plan_contract");
register("password");
register("pending_redemption");
register("helpful_link");
register("showcased_organization");
register("font");
register("analytics_event");
register("template");
register("reviews");
register("dealQualification");
register("managers");
register("embed_url");
register("quote_setting");
register("quote_response");
register("branding_setting");
register("field");
register("form");
register("field_rule");
register("field_file");
register("buyer_user");
register("form_response");
register("quote_request");
register("field_option");

const serializers = partialSerializers;

export { serializers };
