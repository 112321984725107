import type { GetServerSidePropsContext, NextPage } from "next";
import { Suspense, useEffect } from "react";
import { SWRConfig } from "swr";
import { NoSsr } from "@mui/material";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";
import { setAnalyticsPageData } from "../src/dataAccess/storages/analytics";
import {
  DOWNTIME_STATUS_CODE,
  LOGGED_IN_COOKIE_NAME,
  LOGGED_IN_COOKIE_VALUE,
  SSO_REDIRECT_PARAM,
} from "../src/dataAccess/constants";
import { localStorageWrapper } from "../src/dataAccess/LocalStorage";
import { IntercomWrapper } from "../src/app/IntercomWrapper";
import { ServerError } from "../src/dataAccess/ServerError";
import { canAccessPage } from "../src/app/Authorization";
import {
  getRouteFromServerSideContext,
  pageMapping,
  useCurrentRoute,
} from "../src/builder/mapping/page";
import {
  Configuration,
  EmbedUrl,
  communitiesFallbackKey,
  getConfiguration,
} from "../src/dataAccess/api/configuration";
import { SSR } from "../src/helpers/environment";
import { adminKey, useEditor } from "../src/wysiwyg/EditorCtx";
import { App, getPageConfiguration } from "../src/app/App";
import { GlobalStyles } from "../src/app/GlobalStyles";
import Maintenance, { isMaintenanceOn } from "../src/app/Maintenance";
import { UserProvider } from "../src/app/UserProviderCtx";
import { forceIncludeComponents } from "../src/app/componentHooks";
import SiteUnavailable from "../src/components/ui/SiteUnavailable";
import { useSSOPopupListener } from "../src/app/ssoPopup";

datadogRum.init({
  applicationId: "fc441633-0c45-4621-83e3-8ef7c34d8bde",
  clientToken: "pubedf97b73e78ac41c98893584336325c5",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us3.datadoghq.com",
  service: "builtfirst-buyer-frontend",
  env: process.env.NEXT_PUBLIC_API_URL_V3 || "local",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  defaultPrivacyLevel: "mask-user-input",
});

const EditorAuthorization = dynamic(
  () => import("../src/app/EditorAuthorization"),
  {
    suspense: true,
  },
);

interface RequestContext {
  host: string | undefined;
  loggedIn?: boolean;
}

let requestContext: RequestContext;

export function setRequestContext(context: RequestContext) {
  requestContext = context;
}
export function getRequestContext() {
  if (requestContext) {
    return requestContext;
  }
}

export interface Fallback {
  [key: string]: {
    data: Configuration;
    meta: any;
  };
}

function addHeaders({
  context,
  embedUrls,
}: {
  context: GetServerSidePropsContext;
  embedUrls: EmbedUrl[];
}) {
  if (embedUrls) {
    try {
      const urls = embedUrls.map(({ url }) => new URL(url).origin).join(" ");
      if (urls) {
        context.res.setHeader(
          "Content-Security-Policy",
          `frame-ancestors ${urls}`,
        );
      }
    } catch (e) {
      console.error({ embedUrls, e });
    }
  }
}

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const cookies = context.req.headers.cookie || "";
  const { jwt } = context.query;
  const { [SSO_REDIRECT_PARAM]: ssoredirect } = context.query;
  const loggedIn =
    cookies.includes(`${LOGGED_IN_COOKIE_NAME}=${LOGGED_IN_COOKIE_VALUE}`) ||
    !!jwt ||
    !!ssoredirect;
  setRequestContext({ host: context.req.headers.host, loggedIn });

  const route = getRouteFromServerSideContext(context);
  const { json, fallbackKey } = getConfiguration();
  const deafultResponse: {
    props: {
      fallback: null | Fallback;
      SSRRequired: null;
      maintenance: boolean;
    };
  } = {
    props: {
      fallback: null,
      SSRRequired: null,
      maintenance: false,
    },
  };
  return json()
    .then(async (configuration) => {
      const {
        data: { publishedTemplate: theme, isOpenMarketplace, embedUrls },
      } = configuration;

      addHeaders({
        context,
        embedUrls,
      });

      configuration.data = forceIncludeComponents(configuration.data);

      const fallbackConfiguration = { [fallbackKey]: configuration };
      const pageConfiguration = getPageConfiguration(
        theme?.properties?.pages,
        route,
      );
      const currentPage = route?.id && pageMapping[route?.id];

      if (!pageConfiguration) {
        deafultResponse.props.fallback = fallbackConfiguration;
        return deafultResponse;
      }

      const editorMode = !!context.query[adminKey];
      const SSRRequired =
        canAccessPage(isOpenMarketplace, currentPage?.access) &&
        !editorMode &&
        !loggedIn;

      if (currentPage && SSRRequired && "namedExports" in currentPage) {
        const namedExports = await currentPage?.namedExports();
        if (namedExports && "getServerSideProps" in namedExports) {
          let pageFallback: Fallback = {};
          try {
            const {
              props: { fallback },
            } = await namedExports.getServerSideProps(
              context,
              configuration.data,
            );
            pageFallback = fallback;
          } catch (e) {
            console.error(e);
          }

          return {
            props: {
              SSRRequired,
              maintenance: false,
              fallback: {
                ...pageFallback,
                ...fallbackConfiguration,
              },
            },
          };
        }
      }

      return {
        props: {
          SSRRequired,
          fallback: fallbackConfiguration,
        },
      };
    })
    .catch((e) => {
      if (e instanceof ServerError) {
        if (e.getCode() === DOWNTIME_STATUS_CODE) {
          deafultResponse.props.maintenance = true;
        }
        return deafultResponse;
      }
      throw e;
    });
};

type Props = Awaited<ReturnType<typeof getServerSideProps>>["props"];

const Page: NextPage<Props> = ({
  fallback,
  SSRRequired,
  maintenance,
}: Props) => {
  const route = useCurrentRoute();
  const router = useRouter();
  const { editorValue, editorParamExist, templateId } = useEditor();

  const publishedTemplateId = fallback
    ? fallback[communitiesFallbackKey].data.publishedTemplate?.id
    : undefined;

  const customTemplateId = Number(templateId);

  if (!SSR) {
    setRequestContext({ host: window.location.host });
  }

  useEffect(() => {
    setAnalyticsPageData();
  }, [router]);

  useSSOPopupListener();

  if (
    (maintenance ||
      isMaintenanceOn() ||
      process.env.NEXT_PUBLIC_MAINTENANCE === "true") &&
    !localStorageWrapper.getItem("bypass")
  ) {
    return (
      <IntercomWrapper>
        <Maintenance />
      </IntercomWrapper>
    );
  }

  if (!fallback) {
    return <>Error reading community configuration</>;
  }

  if (
    !fallback[communitiesFallbackKey].data.publishedTemplate &&
    !editorParamExist
  ) {
    return SSR ? <></> : <SiteUnavailable />;
  }

  if (SSR && !SSRRequired) {
    return <></>;
  }

  const PageComponent = route?.id && pageMapping[route?.id].component;

  const app = <App PageComponent={PageComponent} fallback={fallback} />;

  return (
    <Suspense>
      <SWRConfig
        value={{
          fallback: fallback || undefined,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          // This must be false so that if there is nothing in cache a request is executed
          revalidateIfStale: false,
          // This must be undefined so avoid refeshing the page too many times
          revalidateOnMount: undefined,
        }}
      >
        <UserProvider>
          <GlobalStyles />
          {!SSR && editorParamExist ? (
            <NoSsr>
              <EditorAuthorization
                template={
                  fallback[communitiesFallbackKey].data.publishedTemplate
                }
                isEditor={editorParamExist}
                adminValue={editorValue || ""}
                templateId={
                  !Number.isNaN(customTemplateId)
                    ? customTemplateId
                    : publishedTemplateId
                }
              >
                {app}
              </EditorAuthorization>
            </NoSsr>
          ) : (
            <>{app}</>
          )}
        </UserProvider>
      </SWRConfig>
    </Suspense>
  );
};

export default Page;
