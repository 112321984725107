import {
  Breakpoint,
  createTheme,
  darken,
  getContrastRatio,
  getLuminance,
  lighten,
  ThemeOptions,
} from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import type { Property } from "csstype";
import { CSSProperties } from "react";
import { Configuration } from "../dataAccess/api/configuration";
import {
  defaultAlphaConstant,
  defaultColorsConstant,
  defaultFontsConstant,
  getDefaultThemeSettings,
} from "../../theme/defaultValues";
import {
  Alignment,
  CustomThemeSettings,
  DeviceLayoutOptions,
  SettingsToMap,
  Size,
  ThemeSettings,
} from "../types/theme";
import { PageSettings } from "./PageConfiguration";
import { getIconsBaseClass, iconSets } from "./icons";
import type { Page } from "../wysiwyg/JsonThemeObject";
import { dataVirtualWidthAttribute } from "../wysiwyg/EditorCtx";

// This is the ratio recommended by the WCAG 2.1 guidelines https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
const minimunContrastRatio = 4.5;

const generateContrastTextColor = (color: string) =>
  getLuminance(color) < 0.5 ? lighten(color, 1) : darken(color, 1);

const generateColor = (color: string, contrastTextColor?: string) => ({
  main: color,
  dark: darken(color, defaultAlphaConstant.alphaDark),
  light: lighten(color, defaultAlphaConstant.alphaLight),
  contrastText: contrastTextColor || generateContrastTextColor(color),
});

const generateBgColor = (color: string) => ({
  default: color,
  paper: lighten(color, defaultAlphaConstant.alphaLight),
});

const generateTextColorByBackground = (
  backgroundColor: { default: string; paper: string },
  color?: string,
) => {
  const contrastRatio = getContrastRatio(
    backgroundColor.default,
    backgroundColor.paper,
  );

  if (color) {
    return {
      default: color,
      paper:
        contrastRatio > minimunContrastRatio
          ? lighten(color, defaultAlphaConstant.alphaLight)
          : color,
    };
  }
  const contrastColor = generateContrastTextColor(backgroundColor.default);
  return {
    default: contrastColor,
    paper:
      contrastRatio > minimunContrastRatio
        ? lighten(contrastColor, defaultAlphaConstant.alphaLight)
        : contrastColor,
  };
};

function generatePalette(
  defaultColors: typeof defaultColorsConstant,
  themeSettings?: ThemeSettings,
): ThemeOptions["palette"] {
  const pagesBackground = generateBgColor(
    themeSettings?.colors?.pages?.backgroundColor || defaultColors.whiteDefault,
  );
  const pagesTextColor = generateTextColorByBackground(
    pagesBackground,
    themeSettings?.colors?.pages?.textColor,
  );
  const panelsBackground = generateBgColor(
    themeSettings?.colors?.panels?.backgroundColor ||
      defaultColors.panelsDefault,
  );
  const panelsTextColor = generateTextColorByBackground(
    panelsBackground,
    themeSettings?.colors?.panels?.textColor,
  );
  const tileBackground = generateBgColor(
    themeSettings?.colors?.tiles?.backgroundColor || defaultColors.whiteDefault,
  );
  const tileTextColor = generateTextColorByBackground(
    tileBackground,
    themeSettings?.colors?.tiles?.textColor,
  );
  const tileOthersColor = generateColor(
    themeSettings?.colors?.tiles?.othersColor ||
      defaultColors.validationSuccess,
  );

  const chipsBackground = generateBgColor(
    themeSettings?.colors?.chips?.static?.backgroundColor ||
      defaultColors.whiteDefault,
  );
  const chipsTextColor =
    themeSettings?.colors?.chips?.static?.textColor ||
    defaultColors.primaryDefault;
  const chipsBorderColor = generateColor(
    themeSettings?.colors?.chips?.static?.borderColor ||
      themeSettings?.colors?.chips?.static?.textColor ||
      defaultColors.primaryDefault,
  );
  const chipsHoverBackground = generateBgColor(
    themeSettings?.colors?.chips?.hover?.backgroundColor ||
      themeSettings?.colors?.chips?.static?.textColor ||
      defaultColors.primaryDefault,
  );
  const chipsHoverTextColor =
    themeSettings?.colors?.chips?.hover?.textColor ||
    themeSettings?.colors?.chips?.static?.backgroundColor ||
    defaultColors.whiteDefault;
  const chipsHoverBorderColor = generateColor(
    themeSettings?.colors?.chips?.hover?.borderColor ||
      themeSettings?.colors?.chips?.hover?.textColor ||
      themeSettings?.colors?.chips?.static?.backgroundColor ||
      defaultColors.primaryDefault,
  );
  const chipsSelectedBackground = generateBgColor(
    themeSettings?.colors?.chips?.selected?.backgroundColor ||
      themeSettings?.colors?.chips?.static?.textColor ||
      defaultColors.primaryDefault,
  );
  const chipsSelectedTextColor =
    themeSettings?.colors?.chips?.selected?.textColor ||
    themeSettings?.colors?.chips?.static?.backgroundColor ||
    defaultColors.whiteDefault;
  const chipsSelectedBorderColor = generateColor(
    themeSettings?.colors?.chips?.selected?.borderColor ||
      themeSettings?.colors?.chips?.selected?.textColor ||
      themeSettings?.colors?.chips?.static?.backgroundColor ||
      defaultColors.primaryDefault,
  );

  const inputsBackground = generateBgColor(
    themeSettings?.colors?.inputs?.backgroundColor ||
      defaultColors.whiteDefault,
  );
  const inputTextColor = generateTextColorByBackground(
    inputsBackground,
    themeSettings?.colors?.inputs?.textColor,
  );
  const buttonsOutline = generateColor(
    themeSettings?.colors.buttons.borderColor ||
      themeSettings?.colors?.buttons?.backgroundColor ||
      defaultColors.primaryDefault,
  );
  const secondaryButtonsOutline = generateColor(
    themeSettings?.colors.buttons?.secondaryButtons?.borderColor ||
      themeSettings?.colors?.buttons?.secondaryButtons?.backgroundColor ||
      buttonsOutline.main ||
      defaultColors.primaryDefault,
  );
  return {
    primary: generateColor(
      themeSettings?.colors?.primary || defaultColors.primaryDefault,
    ),
    secondary: generateColor(
      themeSettings?.colors?.secondary || defaultColors.secondaryDefault,
    ),
    other: generateColor(
      themeSettings?.colors?.other || pagesTextColor.default,
    ),
    success: generateColor(
      themeSettings?.colors?.validation?.successColor ||
        defaultColors.validationSuccess,
    ),
    warning: generateColor(
      themeSettings?.colors?.validation?.warningColor ||
        defaultColors.validationWarning,
    ),
    error: generateColor(
      themeSettings?.colors?.validation?.errorColor ||
        defaultColors.validationError,
    ),
    inputs: inputsBackground,
    inputsUnderline: generateColor(
      themeSettings?.colors.inputs.borderColor ||
        defaultColors.inputsUnderlineDefault,
    ),
    buttons: generateColor(
      themeSettings?.colors?.buttons?.backgroundColor ||
        defaultColors.primaryDefault,
      themeSettings?.colors?.buttons?.textColor,
    ),
    secondaryButtons: generateColor(
      themeSettings?.colors?.buttons?.secondaryButtons?.backgroundColor ||
        themeSettings?.colors?.buttons?.backgroundColor ||
        defaultColors.primaryDefault,
      themeSettings?.colors?.buttons?.secondaryButtons?.textColor ||
        themeSettings?.colors?.buttons?.textColor,
    ),
    background: pagesBackground,
    tileBackground,
    tileOthersColor,
    panelsBackground,
    chipsBackground,
    chipsHoverBackground,
    chipsSelectedBackground,
    chipsBorderColor,
    chipsHoverBorderColor,
    chipsSelectedBorderColor,
    buttonsOutline,
    secondaryButtonsOutline,
    text: {
      paper: pagesTextColor.paper,
      default: pagesTextColor.default,
      panelsPaper: panelsTextColor.paper,
      panelsDefault: panelsTextColor.default,
      tilePaper: tileTextColor.paper,
      tileDefault: tileTextColor.default,
      chipDefault: chipsTextColor,
      chipHover: chipsHoverTextColor,
      chipSelected: chipsSelectedTextColor,
      inputPaper: inputTextColor.paper,
      inputDefault: inputTextColor.default,
    },
  };
}

export function splitFontNameByComma(name?: string) {
  const [result] = (name || "").split(",");
  return result;
}

export function parseConfigFontName(name?: string) {
  const result = splitFontNameByComma(name);
  return result?.replace(/[^a-z0-9-_\s]/gi, "");
}

const fontSizeMultipliers: Record<Size["size"], number> = {
  extraSmall: 0.9,
  small: 0.95,
  medium: 1,
  large: 1.05,
  extraLarge: 1.1,
};

const baseFontSizeMultipliers: Record<Size["size"], number> = {
  extraSmall: 0.65,
  small: 0.8,
  medium: 1,
  large: 1.2,
  extraLarge: 1.35,
};

const getBaseFontSizeMultiplier = (size: Size["size"] = "medium") =>
  baseFontSizeMultipliers[size] || baseFontSizeMultipliers.medium;

const getFontSizeMultiplier = (size: Size["size"] = "medium") =>
  fontSizeMultipliers[size] || fontSizeMultipliers.medium;

const fontSizes = {
  h1: 3,
  h2: 2.25,
  h3: 1.85,
  h4: 1.5,
  h5: 1.25,
  h6: 1.1,
  subtitle1: 1,
  subtitle2: 0.875,
  body1: 1,
  body2: 0.875,
  caption: 0.75,
};

export const getFontStyles = (
  fontType: keyof typeof fontSizes | number,
  baseSize: Size["size"] = "medium",
  fontSize: Size["size"] = "medium",
) => {
  const baseFontSize =
    typeof fontType === "number" ? fontType : fontSizes[fontType];
  const size =
    baseFontSize *
    getBaseFontSizeMultiplier(baseSize) *
    getFontSizeMultiplier(fontSize);
  return {
    fontSize: `${size}rem`,
  };
};

const fallbackFonts = ["Arial", "sans-serif"];

function createCustomTheme(configuration?: Configuration) {
  const themeSettings = configuration?.publishedTemplate.properties.settings;
  const { brandingSetting } = configuration || {};

  const defaultPrimaryColor =
    brandingSetting?.primaryColor || defaultColorsConstant.primaryDefault;
  const defaultSecondaryColor =
    brandingSetting?.secondaryColor || defaultColorsConstant.secondaryDefault;
  const currentBodyFontFamily =
    parseConfigFontName(themeSettings?.fonts.body.fontFamily) ||
    brandingSetting?.bodyFontFamily ||
    defaultFontsConstant.body;
  const currentHeaderFontFamily =
    parseConfigFontName(themeSettings?.fonts.headers.fontFamily) ||
    brandingSetting?.headerFontFamily ||
    defaultFontsConstant.headers;

  const headersFontFamily = [
    `'${currentHeaderFontFamily}'`,
    ...fallbackFonts,
  ].join(",");
  const bodyFontFamily = [`'${currentBodyFontFamily}'`, ...fallbackFonts].join(
    ",",
  );

  const defaultTheme = getDefaultThemeSettings({
    bodyFontFamily,
    headersFontFamily,
  });

  const themeDefaults: ThemeOptions = createTheme(defaultTheme);
  themeDefaults.palette = generatePalette(
    {
      ...defaultColorsConstant,
      primaryDefault: defaultPrimaryColor,
      secondaryDefault: defaultSecondaryColor,
    },
    themeSettings,
  );
  themeDefaults.typography = {
    fontFamily: bodyFontFamily,
    allVariants: {
      color: themeDefaults.palette?.text?.default,
    },
    // Caution: Headers fontSize should be in descendent order
    h1: {
      fontFamily: headersFontFamily,
      ...getFontStyles(
        "h1",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.headers.size,
      ),
      fontWeight: "bold",
    },
    h2: {
      ...getFontStyles(
        "h2",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.headers.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    h3: {
      ...getFontStyles(
        "h3",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.headers.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    h4: {
      ...getFontStyles(
        "h4",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.headers.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    h5: {
      ...getFontStyles(
        "h5",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.headers.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    h6: {
      ...getFontStyles(
        "h6",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.headers.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    subtitle1: {
      ...getFontStyles(
        "subtitle1",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.body.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    subtitle2: {
      ...getFontStyles(
        "subtitle2",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.body.size,
      ),
      fontFamily: headersFontFamily,
      fontWeight: "bold",
    },
    body1: {
      ...getFontStyles(
        "body1",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.body.size,
      ),
      fontFamily: bodyFontFamily,
    },
    body2: {
      ...getFontStyles(
        "body2",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.body.size,
      ),
      fontFamily: bodyFontFamily,
    },
    caption: {
      ...getFontStyles(
        "caption",
        themeSettings?.fonts.baseSize,
        themeSettings?.fonts.body.size,
      ),
      fontFamily: bodyFontFamily,
    },
  };
  themeDefaults.settings = deepmerge(themeDefaults.settings, themeSettings);
  themeDefaults.components = {
    MuiIcon: {
      defaultProps: {
        baseClassName: getIconsBaseClass(themeDefaults.settings?.iconSet),
      },
    },
  };
  return themeDefaults;
}
export enum MediaSetting {
  top = "#setting-top#",
  bottom = "#setting-bottom#",
  maxWidth = "#setting-maxWidth#",
}

function mapSettingsToStyles(
  settingsProps: SettingsToMap,
  themeSettings?: CustomThemeSettings,
  pageSettings?: PageSettings,
  breakpoint?: Breakpoint,
) {
  const breakpoints: Partial<{
    [bp in Breakpoint]: DeviceLayoutOptions | undefined;
  }> = {
    sm: pageSettings?.layout?.tablet || themeSettings?.layout?.tablet,
    md: pageSettings?.layout?.desktop || themeSettings?.layout?.desktop,
    lg: pageSettings?.layout?.large || themeSettings?.layout?.large,
    xl: pageSettings?.layout?.extra || themeSettings?.layout?.extra,
  };
  const currentBreakpoint =
    (breakpoint && breakpoints[breakpoint]) ||
    pageSettings?.layout?.mobile ||
    themeSettings?.layout?.mobile;

  if (breakpoint && currentBreakpoint === themeSettings?.layout?.mobile) {
    return {};
  }

  const styles: { [prop: string]: CSSProperties[keyof CSSProperties] } = {};

  Object.keys(settingsProps).map((prop) => {
    let value = settingsProps[prop];
    (
      Object.entries(MediaSetting) as [
        keyof typeof MediaSetting,
        MediaSetting,
      ][]
    ).forEach(([key, setting]) => {
      value = value?.replace(
        setting,
        String((currentBreakpoint && currentBreakpoint[key]) || ""),
      );
    });
    value = /^\d+$/.test(value) ? `${value}px` : value;
    styles[prop] = value;
  });
  return styles;
}

export const createThemeOptions = (
  configuration?: Configuration,
  page?: Page,
  admin?: boolean,
): ThemeOptions => {
  const theme = createCustomTheme(configuration);
  if (theme.settings?.iconSet) {
    theme.icons = iconSets[theme.settings?.iconSet];
  }
  if (
    theme.breakpoints &&
    "up" in theme.breakpoints &&
    "down" in theme.breakpoints
  ) {
    const { values } = theme.breakpoints || {};
    if (admin && values) {
      const getMainSizeBreakpoints = (key: number | Breakpoint) => {
        const mainApp = document.querySelector(
          `[${dataVirtualWidthAttribute}]`,
        );
        const virtualWidth = parseInt(
          mainApp?.getAttribute(dataVirtualWidthAttribute) || "0",
          10,
        );
        const breakpoint = typeof key === "number" ? key : values[key];
        return { virtualWidth, breakpoint };
      };
      const minimumMediaQuery = "@media (min-width: 0)";
      theme.breakpoints.up = (key: number | Breakpoint) => {
        const { virtualWidth, breakpoint } = getMainSizeBreakpoints(key);
        return breakpoint <= virtualWidth ? minimumMediaQuery : "";
      };
      theme.breakpoints.down = (key: number | Breakpoint) => {
        const { virtualWidth, breakpoint } = getMainSizeBreakpoints(key);
        return breakpoint >= virtualWidth ? minimumMediaQuery : "";
      };
    }
    const up = theme.breakpoints.up!;
    theme.getMediaForSettings = (fn) => {
      const styles = fn();
      const xs = mapSettingsToStyles(styles, theme.settings, page?.settings);
      const sm = mapSettingsToStyles(
        styles,
        theme.settings,
        page?.settings,
        "sm",
      );
      const md = mapSettingsToStyles(
        styles,
        theme.settings,
        page?.settings,
        "md",
      );
      const lg = mapSettingsToStyles(
        styles,
        theme.settings,
        page?.settings,
        "lg",
      );
      const xl = mapSettingsToStyles(
        styles,
        theme.settings,
        page?.settings,
        "xl",
      );
      return {
        ...xs,
        ...(Object.keys(sm).length ? { [up("sm")]: sm } : {}),
        ...(Object.keys(md).length ? { [up("md")]: md } : {}),
        ...(Object.keys(lg).length ? { [up("lg")]: lg } : {}),
        ...(Object.keys(xl).length ? { [up("xl")]: xl } : {}),
      };
    };
  }

  return theme;
};

export const getJustificationByAlignment = (alignment?: Alignment) => {
  const justification: Record<Alignment, Property.JustifyContent> = {
    center: "center",
    left: "flex-start",
    right: "flex-end",
  };
  return (alignment && justification[alignment]) || justification.left;
};
