import { CSSObject } from "@emotion/react";
import { alpha, Button, ButtonProps, styled } from "@mui/material";
import React, { ReactNode } from "react";
import Link from "next/link";
import type { CustomPaletteOptions, Size } from "../../../types/theme";
import { useCapitalization, useShadows } from "../../../builder/Theme";
import { BodySizes } from "../../../builder/useFontsLayout";

export interface Props {
  customVariant?: "secondary";
}

export const ThemeButton = styled(
  React.forwardRef(
    (
      props: ButtonProps & {
        children?: ReactNode;
        target?: string;
      },
      ref: React.Ref<HTMLButtonElement>,
    ) => {
      const href = props?.href || "";
      const nextLink = href && !/^https?:\/\//i.test(href);
      const button = (
        <Button
          variant={props.variant ? props.variant : "contained"}
          ref={ref}
          {...props}
        />
      );
      return nextLink ? (
        <Link href={href} passHref legacyBehavior shallow>
          {button}
        </Link>
      ) : (
        button
      );
    },
  ),
)<Props>(({ theme, variant, customVariant }) => {
  const { buttons: config, fonts } = theme.settings;
  const isSecondary =
    customVariant === "secondary" &&
    config.secondaryButtons.useSecondaryButtons;

  const buttons = isSecondary ? config.secondaryButtons : config;

  const paletteKey: keyof CustomPaletteOptions = isSecondary
    ? "secondaryButtons"
    : "buttons";

  const textTransform = useCapitalization(buttons?.capitalization);
  const boxShadow = useShadows(buttons?.shadows);

  function getPadding(paddingSize: number) {
    const padding = theme.spacing(paddingSize);
    const sidePadding = theme.spacing(paddingSize + 1);
    return {
      padding,
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
    };
  }

  const sizes: Record<Size["size"], CSSObject> = {
    extraSmall: getPadding(0.3),
    small: getPadding(0.5),
    medium: getPadding(0.625),
    large: getPadding(1.2),
    extraLarge: getPadding(2.5),
  };

  const variants: Partial<
    Record<"text" | "outlined" | "contained", CSSObject>
  > = {
    text: {
      color: theme.palette[paletteKey].main,
      fontWeight: `${700}!important`,
    },
    contained: {
      background: theme.palette[paletteKey].main,
      color: theme.palette[paletteKey].contrastText,
      borderColor: alpha(
        theme.palette[`${paletteKey}Outline`].main,
        buttons.border.opacity,
      ),
      "&:hover": {
        background: theme.palette[paletteKey].dark,
      },
    },
    outlined: {
      color: theme.palette[`${paletteKey}Outline`].main,
      borderColor: alpha(
        theme.palette[`${paletteKey}Outline`].main,
        buttons.border.opacity,
      ),
      "&:hover": {
        background: alpha(theme.palette[paletteKey].main, 0.03),
      },
    },
  };

  const variantStyles = variants[variant || "contained"];

  return {
    ...sizes[buttons.size],
    fontSize:
      theme.typography[BodySizes[fonts.buttons?.size || "medium"]].fontSize,
    textTransform,
    boxShadow,
    borderStyle: "solid",
    borderWidth: `${buttons.border.thickness}px`,
    borderRadius: buttons.border.radius * 5,
    ...variantStyles,
    "&:disabled": {
      background: theme.palette.grey[300],
      color: theme.palette.grey[500],
      border: "none",
    },
  };
});
